<template>
  <div>
    <div style="margin-bottom: 24px">
      <a-card :bordered="false">
        <div v-if="hasDeletePermission" class="adjust-button-panel">
<!--          <a-button @click="cancelBill" class="adjust-button">删除账单</a-button>-->
<!--          <a-button
            v-if="addAdjustModalVisible"
            @click="openBillCostAdjustModal"
            class="adjust-button"
          >新增调整账单</a-button>-->
        </div>
        <DescriptionList title="商户信息" size="large" style="margin-bottom: 32px">
          <div style="margin-top: 16px">
            <Description term="门店编号">
              <label>{{ billDetail.facilityCode }}</label>
            </Description>
            <Description term="门店名称">
              <label>{{ billDetail.facilityName }}</label>
            </Description>
            <Description term="档口编号">
              <label>{{ billDetail.kitchenName }}</label>
            </Description>
            <Description term="店铺名称">
              <label>{{ billDetail.storeName }}</label>
            </Description>
            <Description term="商户名称">
              <label>{{ billDetail.tenantName }}</label>
            </Description>
            <Description term="当前账单合同">
              <a @click="contractDetail(billDetail.contractId)">
                {{
                billDetail.contractCode
                }}
              </a>
            </Description>
            <Description term="退租申请记录">
              <span>{{ billDetail.rentOutId }}</span>
            </Description>
            <Description term="合同签约人">
              <span>{{ signEntity }}</span>
            </Description>
          </div>
        </DescriptionList>
        <a-divider dashed />
        <DescriptionList title="账单信息" size="large">
          <div style="margin-top: 16px">
            <Description term="账单编号">
              <label>{{ billDetail.code }}</label>
            </Description>
            <Description term="账单类型">
              <label>{{ billDetail.typeName }}</label>
            </Description>
            <Description term="账单周期">
              <label>{{ billDetail.billDate }}</label>
            </Description>
            <Description term="账单确认状态">
              <label>
                {{
                billDetail.confirmStatusName
                }}
              </label>
            </Description>
            <Description term="账单确认时间">
              <label v-if="!!billDetail.confirmDateLg">
                {{
                moment(billDetail.confirmDateLg).format("YYYY-MM-DD")
                }}
              </label>
            </Description>
            <Description term="是否需支付">
              <label>{{ billDetail.isCanPayName }}</label>
            </Description>
          </div>
        </DescriptionList>
        <a-divider dashed />
        <div style="display: inline-block; margin-bottom: 24px">
          <label class="listTitle">费用明细</label>
        </div>
        <DescriptionList>
          <div style="margin-top: 16px">
            <Description term="账单总金额">
              <label class="amount">{{ billDetail.amount }}</label>
            </Description>
            <Description term="调整后金额">
              <label class="amount">{{ billDetail.afterExtAmount }}</label>
            </Description>
            <Description term="支付状态">
              <label>{{ billDetail.payStatusName }}</label>
            </Description>
          </div>
        </DescriptionList>
        <a-divider dashed />
        <div style="display: inline-block; margin-bottom: 24px">
          <label class="listTitle">退还部分</label>
        </div>
        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            :pagination="false"
            :columns="returnColumns"
            :dataSource="returnTableData"
            :scroll="{ x: 1200 }"
          >
            <span slot="cycle" slot-scope="text, record" width="200">
              <template>
                <span v-if="!!record.cycleStartTimeLg">
                  {{ moment(record.cycleStartTimeLg).format("YYYY-MM-DD") }} ~
                  {{ moment(record.cycleEndTimeLg).format("YYYY-MM-DD") }}
                </span>
              </template>
            </span>
          </a-table>
        </div>
        <a-divider dashed />
        <div style="display: inline-block; margin-bottom: 24px">
          <label class="listTitle">需缴费部分</label>
        </div>
        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            :pagination="false"
            :columns="payColumns"
            :dataSource="payTableData"
            :scroll="{ x: 1200 }"
          >
            <span slot="cycle" slot-scope="text, record" width="200">
              <template>
                <span v-if="!!record.cycleStartTimeLg">
                  {{ moment(record.cycleStartTimeLg).format("YYYY-MM-DD") }} ~
                  {{ moment(record.cycleEndTimeLg).format("YYYY-MM-DD") }}
                </span>
              </template>
            </span>
          </a-table>
        </div>
        <a-divider dashed />
        <div style="display: inline-block; margin-bottom: 24px">
          <label class="listTitle">历史未缴费账单</label>
        </div>
        <div style="margin-top: 16px">
          <a-table
            :pagination="false"
            :columns="historyColumns"
            :dataSource="historyTableData"
            :scroll="{ x: 1200 }"
          >
            <span slot="view" slot-scope="text, record" class="blueColor">
              <template>
                <a @click="viewHistoryItem(record.id)">{{ record.code }}</a>
              </template>
            </span>
          </a-table>
        </div>
        <div v-if="billExt">
          <a-divider dashed />
          <DescriptionList title="调整账单" size="large">
            <div style="margin-top: 16px">
              <Description term="关联调整账单">
                <label>{{ billExt.code }}</label>
              </Description>
              <Description term="调整账单金额">
                <label>{{ billExt.amountYuan }}</label>
              </Description>
            </div>
          </DescriptionList>
          <a-table
            rowKey="id"
            :pagination="false"
            :columns="exColumns"
            :dataSource="billExt.billExtDetail"
            :scroll="{ x: 1200 }"
          >
            <span slot="cycle" slot-scope="text, record" width="200">
              <template>
                <span>
                  {{ moment(record.cycleStartTime).format("YYYY-MM-DD") }} ~
                  {{ moment(record.cycleEndTime).format("YYYY-MM-DD") }}
                </span>
              </template>
            </span>
          </a-table>
        </div>
        <div v-if="billDetail.amount * 1 > 0">
          <a-divider dashed />
          <div style="display: inline-block; margin-bottom: 24px">
            <label class="listTitle">支付信息</label>
          </div>
          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              :pagination="false"
              :columns="p1columns"
              :dataSource="p1TableData"
              :scroll="{ x: 1200 }"
            >
              <span slot="action" slot-scope="text, record" class="blueColor">
                <template>
                  <a v-if="record.payChannel == 3" @click="viewVoucher(record)">查看凭证</a>&nbsp;&nbsp;
                  <a
                    v-if="record.payStatus == 4 && record.payChannel == 3"
                    @click="viewGain(record)"
                  >确认到账</a>
                </template>
              </span>
            </a-table>
          </div>
        </div>
        <div v-else>
          <a-divider dashed />
          <div class="btBox" style="margin-bottom: 24px">
            <label class="listTitle">支付信息</label>
            <div class="fr">
              <a-button style="margin-left: 8px" type="primary" @click="viewSettle">标记打款</a-button>
            </div>
          </div>
          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              :pagination="false"
              :columns="p2columns"
              :dataSource="p2TableData"
              :scroll="{ x: 1200 }"
            ></a-table>
          </div>
        </div>
      </a-card>
      <!-- 转账凭证 model -->
      <a-modal
        title="查看凭证"
        :width="500"
        :visible="isViewVoucher"
        :footer="null"
        @cancel="cancelViewVoucher"
        destroyOnClose
      >
        <div class="vBox">
          <div style="margin-top: 16px">
            <Description term="转账日期">
              <label v-if="!!voucherObj.transferTimeLg">
                {{
                moment(voucherObj.transferTimeLg).format("YYYY-MM-DD HH:mm:ss")
                }}
              </label>
            </Description>
          </div>
          <div style="margin-top: 16px">
            <Description term="支付凭证图片"></Description>
            <div v-if="voucherObj.payPoofImgList">
              <img
                style="margin-top: 8px;width:100%;height:100%;"
                v-for="url in voucherObj.payPoofImgList"
                v-bind:key="url"
                :src="url"
              />
            </div>
          </div>
          <div style="margin-top: 16px">
            <Description term="支付确认时间">
              <label v-if="!!voucherObj.payConfirmDateLg">
                {{
                moment(voucherObj.payConfirmDateLg).format(
                "YYYY-MM-DD HH:mm:ss"
                )
                }}
              </label>
            </Description>
          </div>
          <div style="margin-top: 16px">
            <Description term="支付确认人">
              <label>{{ voucherObj.paymentConfirmed }}</label>
            </Description>
          </div>
        </div>
      </a-modal>
      <!-- 确认到账 -->
      <a-modal
        title="确认到账"
        :width="600"
        :visible="isViewGain"
        :confirmLoading="gainConfirmLoading"
        @ok="submitGain"
        @cancel="cancelViewGain"
        destroyOnClose
      >
        <Description term="支付金额">
          <label>{{ gainObj.payAmount }}</label>
        </Description>
        <Description>
          <a-radio-group @change="onChangeRadioGain" name="radioGroup" :defaultValue="3">
            <a-radio :value="3">已收到款</a-radio>
            <a-radio :value="1">未收到款</a-radio>
          </a-radio-group>
        </Description>
      </a-modal>
      <!-- 标记打款 -->
      <a-modal
        title="标记打款"
        :width="600"
        :visible="isViewSettle"
        :confirmLoading="settleConfirmLoading"
        @ok="submitSettle"
        @cancel="cancelViewSettle"
        destroyOnClose
      >
        <Description term="收款人" style="margin: 8px;width: 300px;">
          <a-input v-model="settleObj.payee" />
        </Description>
        <Description term="打款金额" style="margin: 8px;width: 300px;">
          <a-input v-model="settleObj.amount" />
        </Description>
        <Description term="打款时间" style="margin: 8px;width: 300px;">
          <a-date-picker
            style="width: 100%;"
            @change="getSettleDate"
            format="YYYY-MM-DD HH:mm:ss"
            :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
          />
        </Description>
        <Description term="收款银行" style="margin: 8px;">
          <a-input v-model="settleObj.bank" />
        </Description>
        <Description term="收款账户" style="margin: 8px;">
          <a-input v-model="settleObj.account" />
        </Description>
      </a-modal>
    </div>
    <BillCostAdjustModal
      v-if="billCostAdjustModalOpen"
      :closeAction="closeBillCostAdjustModal"
      :refBillId="inanceBailDetailId"
    />
  </div>
</template>

<script>
import { modal } from "ant-design-vue";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import moment from "moment";
import axios from "axios";
  
import Bus from "@/bus";
import BillCostAdjustModal from "../components/BillCostAdjustModal";
import { mapGetters } from "vuex";
import { hasPermissions } from "../../../utils/auth";
import { OrganizationActions } from "../../../utils/actions";

export default {
  name: "finSettlementDetail",
  components: {
    BillCostAdjustModal,
    DescriptionList,
    Description,
  },
  data() {
    return {
      // 路由信息
      inanceBailDetailId: this.$route.query.financeBailDetailId,
      // 基本信息
      billDetail: {},
      // payColumns
      // 退还部分
      returnColumns: [
        {
          title: "费用类型",
          dataIndex: "typeName",
        },
        {
          title: "账单周期",
          scopedSlots: { customRender: "cycle" },
          key: "cycle",
        },
        {
          title: "金额(含税价)",
          dataIndex: "totalAmount",
          customRender: (text, row, index) => {
            if (text == 0) {
              return text;
            }
            if (row.handleType == 2) {
              return <span>{row.totalAmount}</span>;
            } else if (row.handleType == 1) {
              return <span>-{row.totalAmount}</span>;
            }
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
      ],
      returnTableData: [],
      // 需缴费部分
      payColumns: [
        {
          title: "费用类型",
          dataIndex: "typeName",
        },
        {
          title: "账单周期",
          scopedSlots: { customRender: "cycle" },
          key: "cycle",
        },
        {
          title: "金额（含税价）",
          dataIndex: "totalAmount",
          customRender: (text, row, index) => {
            if (text == 0) {
              return text;
            }
            if (row.handleType == 2) {
              return <span>{row.totalAmount}</span>;
            } else if (row.handleType == 1) {
              return <span>-{row.totalAmount}</span>;
            }
          },
        },
        {
          title: "税点",
          dataIndex: "taxRate",
          key: "taxRate",
        },
        {
          title: "不含税价",
          dataIndex: "afterTaxAmount",
          customRender: (text, row, index) => {
            if (text == 0) {
              return text;
            }
            if (row.handleType == 2) {
              return <span>{row.afterTaxAmount}</span>;
            } else if (row.handleType == 1) {
              return <span>-{row.afterTaxAmount}</span>;
            }
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
      ],
      payTableData: [],
      // 历史未缴纳账单
      historyColumns: [
        {
          title: "账单编号",
          scopedSlots: { customRender: "view" },
          key: "code",
        },
        {
          title: "账单类型",
          dataIndex: "money",
          customRender: (text, row, index) => {
            let status = "";
            switch (row.type * 1) {
              case 1:
                status = "首期款账单";
                break;
              case 2:
                status = "改造费用账单";
                break;
              case 3:
                status = "退租结算账单";
                break;
              case 4:
                status = "基础服务费账单";
                break;
              case 5:
                status = "经营费用账单";
                break;
            }
            return <span>{status}</span>;
          },
        },
        {
          title: "账单周期",
          dataIndex: "type",
          customRender: (text, row, index) => {
            let st = row.cycleStartTimeLg
              ? moment(row.cycleStartTimeLg).format("YYYY-MM-DD")
              : "";
            let dt = row.cycleEndTimeLg
              ? moment(row.cycleEndTimeLg).format("YYYY-MM-DD")
              : "";
            let ct = !!st && !!dt ? st + "~" + dt : "";
            return <span>{ct}</span>;
          },
        },
        {
          title: "账单总金额",
          dataIndex: "amount",
        },
      ],
      historyTableData: [],
      // 支付1
      p1columns: [
        {
          title: "支付金额",
          dataIndex: "payAmount",
        },
        {
          title: "支付方式",
          dataIndex: "payModel",
          customRender: (text, row, index) => {
            let status = "";
            switch (row.payModel * 1) {
              case 1:
                status = "线上支付";
                break;
              case 2:
                status = "线下支付";
                break;
              case 3:
                status = "转账";
                break;
              case 4:
                status = "定金支付";
                break;
              case 5:
                status = "押金抵扣";
                break;
              case 6:
                status = "余额支付";
                break;
            }
            return <span>{status}</span>;
          },
        },
        // `pay_channel` tinyint(4) DEFAULT NULL COMMENT '支付渠道1:支付宝2:微信3:线下转账4:pos机',
        {
          title: "支付渠道",
          dataIndex: "payChannel",
          customRender: (text, row, index) => {
            let status = "";
            switch (row.payChannel * 1) {
              case 1:
                status = "支付宝";
                break;
              case 2:
                status = "微信";
                break;
              case 3:
                status = "线下转账";
                break;
              case 4:
                status = "pos机";
                break;
              case 5:
                status = "二维码支付";
                break;
              case 6:
                status = "POS银行卡";
                break;
              case 7:
                status = "POS扫一扫";
                break;
              case 8:
                status = "账户余额";
                break;
            }
            return <span>{status}</span>;
          },
        },
        // `status` tinyint(4) DEFAULT NULL COMMENT '支付状态1:未支付2:部分支付3:已支付4:已支付,待确认5:支付处理中6:支付失败',
        {
          title: "支付状态",
          dataIndex: "payStatus",
          customRender: (text, row, index) => {
            let status = "";
            switch (row.payStatus * 1) {
              case 1:
                status = "未支付";
                break;
              case 2:
                status = "部分支付";
                break;
              case 3:
                status = "已支付";
                break;
              case 4:
                status = "已支付,待确认";
                break;
              case 5:
                status = "支付处理中";
                break;
              case 6:
                status = "支付失败";
                break;
            }
            return <span>{status}</span>;
          },
        },
        {
          title: "支付时间",
          customRender: (text, row, index) => {
            if (!row.payTimeLg) {
              return;
            } else {
              let rt = moment(row.payTimeLg).format("YYYY-MM-DD HH:mm:ss");
              return <span>{rt}</span>;
            }
          },
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          key: "action",
          fixed: "right",
          width: 180,
        },
      ],
      // p1TableData:[],
      p1TableData: [],

      // `status` tinyint(4) DEFAULT NULL COMMENT '支付状态1:未支付2:部分支付3:已支付4:已支付,待确认5:支付处理中6:支付失败',
      // `bill_pay_id` bigint(20) DEFAULT NULL COMMENT '支付单ID',
      // `pay_time` datetime DEFAULT NULL COMMENT '支付时间',
      // `transfer_time` datetime DEFAULT NULL COMMENT '转账时间',
      // `pay_channel` tinyint(4) DEFAULT NULL COMMENT '支付渠道1:支付宝2:微信3:线下转账4:pos机',
      // `pay_amount` bigint(20) DEFAULT NULL COMMENT '支付金额',
      // `pay_model` tinyint(4) DEFAULT NULL COMMENT '支付方式1:线上支付2:线下支付3:转账4:定金支付',
      // 表格中文

      // 支付2
      p2columns: [
        {
          title: "收款人",
          dataIndex: "payee",
        },
        {
          title: "收款银行",
          dataIndex: "bank",
        },
        {
          title: "收款账户",
          dataIndex: "account",
        },
        {
          title: "打款金额",
          dataIndex: "amount",
        },
        {
          title: "收款时间",
          dataIndex: "payDateLg",
          customRender: (text, row, index) => {
            if (!row.payDateLg) {
              return;
            } else {
              let rt = moment(row.payDateLg).format("YYYY-MM-DD HH:mm:ss");
              return <span>{rt}</span>;
            }
          },
        },
      ],
      p2TableData: [],
      // p2TableData:[],

      // 凭证查看
      isViewVoucher: false,
      voucherObj: {},
      //
      isViewGain: false,
      isConfirmGain:false,
      gainConfirmLoading: false,
      gainObj: {},
      //
      isViewSettle: false,
      settleConfirmLoading: false,
      settleObj: {},
      // 添加调整账单
      billCostAdjustModalOpen: false,
      // 支付状态
      payStatus: null,
      // 关联的调整订单信息
      billExt: null,
      // 详情页调整账单明细
      exColumns: [
        {
          title: "费用类型",
          dataIndex: "costTypeName",
        },
        {
          title: "交易类型",
          dataIndex: "handleType",
          customRender: (text) => {
            if (text === 1) {
              return "减免";
            } else if (text === 2) {
              return "扣除";
            }
          },
        },
        {
          title: "账单周期",
          scopedSlots: { customRender: "cycle" },
          key: "id",
        },
        {
          title: "账单金额",
          dataIndex: "amountYuan",
        },
      ],
      // 合同签约人
      signEntity:''
    };
  },
  created() {
    // 按钮检查
    this.init();
    Bus.$on("getTarget", (target) => {
      this.$router.push({
        path: "/finance/financeBail",
      });
    });
  },
  methods: {
    moment,
    init() {
      this.billDetail = {};
      // GET /fms_css/bill/queryBillDepartureDetail
      axios({
        url:   this.$baseUrl + "bill/queryBillDepartureDetail",
        withCredentials: true,
        method: "GET",
        params: {
          id: this.inanceBailDetailId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.billDetail = res.data.obj;
          this.billDetail.confirmStatusName = this.getConfirmStatus(
            this.billDetail.confirmStatus
          );
          this.historyTableData = this.billDetail.historyList || [];
          let pt = [];
          let rt = [];
          this.billDetail.costList.forEach((item) => {
            if (item.handleType == "1") {
              rt.push(item);
            } else {
              pt.push(item);
            }
          });
          this.payTableData = pt;
          this.returnTableData = rt;
          // this.payTableData = this.billDetail.costList || [];
          // 支付1
          this.p1TableData = this.billDetail.payList || [];
          // 支付2
          this.p2TableData = this.billDetail.refundList || [];

          // 清除 状态
          this.isViewGain = false;
          this.isConfirmGain = false;
          this.gainConfirmLoading = false;
          this.gainObj = {};

          this.isViewSettle = false;
          this.settleConfirmLoading = false;
          this.settleObj = {};
          this.payStatus = this.billDetail.payStatus;
          this.billExt = this.billDetail.billExtVO;

          // 获取合同详情取出合同签约人字段
          this.getContractDetail()
        }
      });
    },
    // contractDetail 查看合同
    contractDetail(id) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: id }
      });
    },
    // 退租记录
    outRentDetail(id) {
      this.$router.push({
        path: "/store/terminateLeaseList/terminateLeaseDetail",
        query: { id: id },
      });
    },
    // 跳转历史账单
    viewHistoryItem(id) {
      this.$router.push({
        path: "/finance/financeBail/financeBailDetail",
        query: {
          financeBailDetailId: id,
        },
      });
    },
    // 查看凭证
    viewVoucher(val) {
      this.voucherObj = {};
      this.voucherObj = val;
      this.isViewVoucher = true;
    },
    cancelViewVoucher() {
      this.isViewVoucher = false;
    },
    //
    viewGain(val) {
      this.gainObj = Object.assign({}, val);
      this.gainObj.check = 3;
      this.gainObj.noShowInput = true;
      this.isViewGain = true;
    },
    submitGain() {
      this.gainObj.payStatus = this.gainObj.check;
      if (this.isConfirmGain) {
        return;
      }
      this.isConfirmGain = true;
      // POST /fms_css/bill/pay/confirmPayment
      axios({
        url:   this.$baseUrl + "bill/pay/confirmPayment",
        withCredentials: true,
        method: "POST",
        data: this.gainObj,
      }).then((res) => {
        if (res.data.success) {
          this.init();
        } else {
          this.$message.error(res.data.returnMsg);
        }
      }).finally(()=>{
        this.isConfirmGain = false;
      });
    },
    cancelViewGain() {
      this.isViewGain = false;
    },
    //
    viewSettle() {
      this.settleObj = {};
      this.isViewSettle = true;
    },
    submitSettle() {
      // POST /fms_css/bill/refund/create
      if (!this.settleObj.account) {
        this.$message.error("提交信息有误！");
        return;
      }
      if (this.settleObj.amount < 0) {
        this.$message.error("提交信息有误！");
        return;
      }
      if (!this.settleObj.bank) {
        this.$message.error("提交信息有误！");
        return;
      }
      if (!this.settleObj.payDate) {
        this.$message.error("提交信息有误！");
        return;
      }
      if (!this.settleObj.payee) {
        this.$message.error("提交信息有误！");
        return;
      }
      this.settleObj.billId = this.inanceBailDetailId;
      this.settleConfirmLoading = true;
      // settleObj
      axios({
        url:   this.$baseUrl + "bill/refund/create",
        withCredentials: true,
        method: "POST",
        data: this.settleObj,
      }).then((res) => {
        if (res.data.success) {
          this.init();
        } else {
          this.$message.error(res.data.returnMsg);
        }
        this.settleConfirmLoading = false;
      });
    },
    cancelViewSettle() {
      this.isViewSettle = false;
    },
    // 账单确认状态
    // tbc(1, "待确认"),
    // system(2, "系统自动确认"),
    // tenant_manual(3, "商户手动确认"),
    // sign_delay(4, "标记延缓确认"),confirmStatus
    getConfirmStatus: (status) => {
      if (!status) {
        return;
      } else {
        switch (status + "") {
          case "1":
            return "待确认";
          case "2":
            return "系统自动确认";
          case "3":
            return "商户手动确认";
          case "4":
            return "标记延缓确认";
        }
      }
    },
    // 获取时间
    getSettleDate(time, timeString) {
      this.settleObj.payDate = time;
    },
    // radio
    onChangeRadioGain(e) {
      // NONE(1, "未支付"),
      // PART(2, "部分支付"),
      // DONE(3, "已支付"),
      // PAY_READY_CONFIRM(4, "已支付，待确认"),
      // PRE_PAY(5, "支付处理中"),
      // PAY_FAIL(6, "支付失败"),
      // CLOSED(7, "支付订单关闭"),
      // REFUND(8, "退款处理中"),
      this.gainObj.check = e.target.value;
      this.gainObj.payStatus = e.target.value;
      if (e.target.value == 1) {
        this.gainObj.noShowInput = false;
        this.gainObj.payOrderNo = "";
      } else {
        this.gainObj.noShowInput = true;
      }
    },
    openBillCostAdjustModal() {
      this.billCostAdjustModalOpen = true;
    },
    closeBillCostAdjustModal(succeed) {
      this.billCostAdjustModalOpen = false;
      if (succeed) {
        this.init();
      }
    },
    getBillExt() {
      axios({
        url:   this.$baseUrl + "bill/getBillExt",
        withCredentials: true,
        method: "GET",
        params: {
          refBillId: this.inanceBailDetailId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.billExt = res.data.obj;
        }
      });
    },
    // 撤销账单
    cancelBill() {
      let that = this;
      modal.confirm({
        title:
          "已发出的账单一旦撤回，则账单会删除，该操作无法逆转，请确认是否继续？",
        onOk() {
          axios({
            url:   that.$baseUrl + "bill/deleteBillById",
            withCredentials: true,
            method: "POST",
            data: {
              billId: that.inanceBailDetailId,
            },
          }).then((res) => {
            if (res.data.success) {
              that.$message.success(res.data.returnMsg);
              that.$router.push({ path: "/finance/financeBail" });
            } else {
              that.$message.error(res.data.returnMsg);
            }
          });
        },
        onCancel() {},
      });
    },
    // 获取合同详情取出合同签约人字段
    getContractDetail(){
      axios({
        url:   this.$baseUrl + "contract/query/detail",
        withCredentials: true,
        method: "GET",
        params: {
          contractId: this.billDetail.contractId,
        },
      }).then((res) => {
        if (res.data.success) {
          this.signEntity = res.data.obj.signEntity;
        }
      });
    }
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    hasDeletePermission() {
      return hasPermissions(
        [OrganizationActions.BILL_EDIT],
        this.authenticatedUser.actions
      );
    },
    addAdjustModalVisible() {
      return this.payStatus === 1 && this.billExt === null;
    },
  },
};
</script>

<style scoped>
.amount {
  font-size: 1.5em;
}
.btBox {
  display: flex;
  justify-content: space-between;
}
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.vBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.adjust-button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 0px;
}
.adjust-button {
  margin: 0px 5px;
}
</style>
